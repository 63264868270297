<template>
  <b-row>
    <b-col cols="12">
      <b-card title="schedule List">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-between">
         <div>
          <!--    <b-button @click="isSidebarActive = true" variant="primary">
              Add New
            </b-button>-->
          </div> 

          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="schedule"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :line-numbers="true"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Status -->
            <span v-if="props.column.field === 'status'">
              <b-badge
                class="hover-pointer"
                @click="
                  initdeleteschedule(
                    props.row.id,
                    'status',
                    props.row.status
                  )
                "
                :variant="statusVariant(props.row.status)"
              >
                {{ props.row.status == 1 ? "Active" : "Inactive" }}
              </b-badge>
            </span>
            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
               
                  <b-badge  variant="primary">
                    <router-link
                      :to="{
                        name: 'master-schedule-edit',
                        params: { id: props.row.id },
                      }"
                    >
                      <feather-icon icon="Edit2Icon" class="mr-50" />

                  </router-link>
                   
                  </b-badge>
                  <!-- <b-badge variant="danger"
                  class="ml-2 hover-pointer"
                    @click="initdeleteschedule(props.row.id, 'delete')"
                  >
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    
                  </b-badge> -->
              
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3', '5', '10']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </b-col>

  
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  VBToggle,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";


import store from "@/store/index";
import { ref, onUnmounted } from "@vue/composition-api";
import usescheduleList from "./usescheduleList";
import scheduleStoreModule from "../scheduleStoreModule";

export default {
  async mounted() {
   await this.fetchschedule();
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {};
  },
  methods: {
    async initdeleteschedule(val, type, status) {
      if (val) {
        let formData = {
          id: val,
          type: type,
          status: status,
        };
        var result = await this.deleteschedule(formData);
        if (result == true) {
          this.refetchschedule();
        }
      }
    },
  },
  setup() {
    // Register module
    const APP_STORE_MODULE_NAME = "master-schedule";

    if (!store.hasModule(APP_STORE_MODULE_NAME))
      store.registerModule(APP_STORE_MODULE_NAME, scheduleStoreModule);

    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME))
        store.unregisterModule(APP_STORE_MODULE_NAME);
    });
    const isSidebarActive = ref(false);

    const {
      fetchschedule,
      refetchschedule,
      deleteschedule,
      resetForm,
      columns,
      pageLength,
      searchTerm,
      schedule,
      statusVariant,
    } = usescheduleList();

    return {
      fetchschedule,
      refetchschedule,
      deleteschedule,
      resetForm,
      columns,
      pageLength,
      searchTerm,
      schedule,
      statusVariant,
      isSidebarActive,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
